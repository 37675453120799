import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

import PaddingWrapper from "./PaddingWrapper"
import {
  twoImgContainerStyles,
  twoImgBlockCls,
  halfImgCls
} from "./TwoImgBlockContent.module.scss"

const TwoImgBlockContent = ({ twoImgBlock, config }) => {
  const { leftImg, rightImg } = twoImgBlock

  return (
    <PaddingWrapper
      className={`${twoImgContainerStyles} ${twoImgBlockCls}`}
      padding={config?.padding}>
      <GatsbyImage
        className={`${halfImgCls} mb-5 mb-lg-0`}
        image={getImage(leftImg.localFile)}
        alt={leftImg?.altText}
      />
      <GatsbyImage
        className={halfImgCls}
        image={getImage(rightImg.localFile)}
        alt={rightImg?.altText}
      />
    </PaddingWrapper>
  )
}

export default TwoImgBlockContent
