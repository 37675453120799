// extracted by mini-css-extract-plugin
export var bgGrey100 = "TwoImgBlockContent-module--bg-grey-100--24269";
export var bgGrey150 = "TwoImgBlockContent-module--bg-grey-150--1e6e1";
export var bgGrey200 = "TwoImgBlockContent-module--bg-grey-200--c0766";
export var bgGrey300 = "TwoImgBlockContent-module--bg-grey-300--13720";
export var bgGrey400 = "TwoImgBlockContent-module--bg-grey-400--9afa6";
export var bgGrey500 = "TwoImgBlockContent-module--bg-grey-500--20c3f";
export var bgGrey600 = "TwoImgBlockContent-module--bg-grey-600--58b19";
export var bgGrey700 = "TwoImgBlockContent-module--bg-grey-700--da329";
export var bgGrey800 = "TwoImgBlockContent-module--bg-grey-800--6e7f0";
export var bgGrey900 = "TwoImgBlockContent-module--bg-grey-900--164b7";
export var halfImgCls = "TwoImgBlockContent-module--halfImgCls--52e3f";
export var textGrey100 = "TwoImgBlockContent-module--text-grey-100--bccb1";
export var textGrey150 = "TwoImgBlockContent-module--text-grey-150--215fb";
export var textGrey200 = "TwoImgBlockContent-module--text-grey-200--e2c7e";
export var textGrey300 = "TwoImgBlockContent-module--text-grey-300--8d19c";
export var textGrey400 = "TwoImgBlockContent-module--text-grey-400--365e2";
export var textGrey500 = "TwoImgBlockContent-module--text-grey-500--0673d";
export var textGrey600 = "TwoImgBlockContent-module--text-grey-600--7b75c";
export var textGrey700 = "TwoImgBlockContent-module--text-grey-700--09a58";
export var textGrey800 = "TwoImgBlockContent-module--text-grey-800--426a3";
export var textGrey900 = "TwoImgBlockContent-module--text-grey-900--5ad4d";
export var twoImgBlockCls = "TwoImgBlockContent-module--twoImgBlockCls--f339d";
export var twoImgContainerStyles = "TwoImgBlockContent-module--twoImgContainerStyles--b5cb9";